@import "variables";
@import "bootstrap";
@import "responsive";

@import "tabs/tabs";
@import "loader/loader";
@import "loader/pie-timer";

// Librairie téléphone
@import 'node_modules/intl-tel-input/build/css/intlTelInput';

.iti__flag {background-image: url("/img/flags/flags.png");}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {background-image: url("/img/flags/flags@2x.png");}
}

//@import "tabs/form-infos";

//Variables
$blue_header: #8EC2E2;
$blue_main: #47a3da;
$green_main: #98CC5F;
$main-color: #fff;

$grey-light: #ccc;
$grey-disabled: #eee;
$blue-efc: #1f5e85;
$blue-efc-medium: #638faa;
$blue-efc-light: #8fafc2;

$panel-green-border: darken($green_main, 10%);
$panel-blue-border: darken($blue_main, 10%);
$panel-text: #fff;
$panel-green-heading-bg: $green_main;
$panel-blue-heading-bg: $blue_main;
$panel-green-border: darken($green_main, 10%);
$panel-blue-border: darken($blue_main, 10%);

// Colors
$flat-green: #1abc9c;
$flat-blue: #07a9e7;
$flat-royalblue: rgba(31, 94, 133, 0.8);
$flat-red: #f4726d;
$flat-orange: #eb974e;
$flat-grey: #777;
$flat-light-grey: #999;
$flat-lighter-grey: #ccc;
$flat-lightest-grey: #eee;




.table-valign-middle > tbody > tr {
    > td, > th {
        vertical-align: middle;
    }
}


// START Custom Design 2016/01/29

h1, h2, h3, h4, h5, h6 {
    color: $flat-grey;
}

.w100 {
    width: 100%;
}

.no-margin {
    margin: 0 !important;
}

.no-margin-top {
    margin-top: 0 !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-top-50 {
    margin-top: 50px !important;
}

.margin-bottom-30 {
    margin-bottom: 30px !important;
}

.no-padding {
    padding: 0 !important;
}

.bold {
    font-weight: 600;
}

.btn {
    transition-duration: .2s;
    border-radius: 0;

    &:hover,
    &:focus,
    &:active {
        outline: none !important;
    }

    &.btn-round {
        border-radius: 2em;
    }
}

.panel {
    border-radius: 0;

    .panel-heading {
        border-radius: 0;
    }
}

.alert {
    border-radius: 0;
}

.label {
    vertical-align: 1px;
}

.tooltip-inner {
    // background-color: $flat-grey;
    border-radius: 0;
}

label {
    color: $flat-grey;
}

input[type="text"], .form-control {
    color: $flat-light-grey !important;
    border: 2px solid $grey-light !important;
    border-radius: 0 !important;
    box-shadow: none !important;

    &:focus,
    &:active {
        border: 2px solid $flat-blue !important;
        box-shadow: none !important;
    }
}

.has-error .form-control {
    border-color: $flat-red !important;
}

input:active,
input:focus {
    outline: none !important;
}

.input-field-focus {
    input,
    select,
    textarea {
        box-shadow: none !important;
        border: 2px solid $flat-blue !important;
    }
}

.has-error {
    .help-block,
    .control-label,
    .radio,
    .checkbox,
    .radio-inline,
    .checkbox-inline,
    .radio-block,
    &.radio label,
    &.checkbox label,
    &.radio-inline label,
    &.checkbox-inline label,
    &.radio-block label {
        color: $flat-red;
    }
}

.text-danger {
    color: $flat-red;
}

.nav > li > a {
    padding: 15px 35px;
    font-size: 20px;
    color: $flat-blue;
    border-radius: 0;
}

.nav-tabs {
    & > li.active > a,
    & > li.active > a:hover,
    & > li.active > a:focus {
        border-bottom-color: transparent;
        border-radius: 0;
    }
}

.table > caption + thead > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > th,
.table > thead:first-child > tr:first-child > td {
    border: 2px solid $flat-lightest-grey;
    // border: 2px solid $flat-lighter-grey;
    border-bottom: 0;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
    border: 2px solid $flat-lightest-grey;
    // border: 2px solid $flat-lighter-grey;
}

.tab-content {
    margin-top: 50px;
}

.modal-content {
    border-radius: 0;
}

#prospect-panel,
#conseillor-panel {
    margin-bottom: 100px;
}

.form-group-padding {
    padding-left: 10px;
}

.upload-loader-container {
    // display: none;
    width: 100%;
    height: 0px;
    background-color: #eee;

    .upload-loader-inner {
        height: 100%;
        width: 0%;
        max-width: 100%;
        background-color: $flat-blue;
        transition-duration: .2s;
    }
}

.close-remove-formations {
    color: #fff;
    font-size: 30px;
    line-height: .7;
    opacity: .7;

    &:hover,
    &:focus,
    &:active {
        color: #fff;
        outline: none;
        opacity: 1;
    }
}

.financement-overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: .5;
    z-index: 2;
}

.success-overlay,
.error-overlay {
    position: fixed !important;
    display: none;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    .success-overlay-table,
    .error-overlay-table {
        display: table;
        width: 100%;
        height: 100%;

        .success-overlay-table-cell,
        .error-overlay-table-cell {
            display: table-cell;
            text-align: center;
            vertical-align: middle;

            h1,
            h3,
            .glyphicon {
                color: #fff;
            }

            .glyphicon {
                font-size: 50px;
            }
        }
    }
}

.success-overlay {
    background-color: rgba($flat-green, 0.7);
}

.error-overlay {
    background-color: rgba($flat-red, 0.7);
}

.form-error-list {
    margin-bottom: 0;
}

#modal-error,
#modal-success,
#modal-aide {
    .modal-content {
        box-shadow: 0 0 50px -5px rgba(255, 255, 255, 0.5);

        .modal-body {
            padding: 0;
        }
    }
}

a:hover,
a:focus {
    color: $flat-grey;
}

form.action-post-deverouillage,
.action-aide {
    position: absolute;
    top: 18px;
    right: 18px;

    ._btn,
    a {
        width: 120px;
        height: 100px;
        padding: 15px;
        font-size: 13.5px;
        text-align: center;

        .glyphicon {
            display: block;
            margin-bottom: 8px;
            font-size: 25px;
        }
    }

    a {
        font-size: 20px !important;
    }
}

.signature-statut-title .glyphicon {
    display: none;
    vertical-align: -3px;
}

.signature-actions {
    display: inline-block;
}

._close {
    background: transparent;
    padding: 0;
    float: right;
    font-size: 25px;
    font-weight: bold;
    line-height: .7;
    color: #fff;
    border: 0;
    text-shadow: 0 1px 0 #fff;

    &:hover {
        opacity: .5;
    }
}

// Colors
._primary {
    color: $flat-blue;

    .label {
        background-color: $flat-blue;
    }
}
._success {
    color: $flat-green;

    .label {
        background-color: $flat-green;
    }
}
._danger {
    color: $flat-red;

    .label {
        background-color: $flat-red;
    }
}
._disabled {
    color: $flat-lighter-grey;

    .label {
        background-color: $flat-lighter-grey;
    }
}

// Button
._btn {
    display: inline-block;
    outline: none;
    margin: 0 auto;
    margin-top: 15px;
    padding: 0.7em 2em;
    border: 2px solid $flat-grey;
    border-radius: 40px;
    background: transparent;
    color: $flat-grey;
    letter-spacing: 1px;
    font-size: 16px;
    font-family: Lato, sans-serif;
    -webkit-tap-highlight-color: transparent;
    -webkit-transition: background-color 0.3s, color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s;
    transition: background-color 0.3s, color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s;

    &:hover,
    &:focus {
        outline: none;
    }

    &:hover {
        border: 2px solid $flat-grey;
        background-color: $flat-grey;
        color: #fff;
    }

    &._btn-square {
        border-radius: 0;
    }

    &._btn-xs {
        margin-top: 10px;
        padding: 0.1em 1em;
        font-size: 14px;
    }

    &._btn-sm {
        margin-top: 15px;
        padding: 0.5em 1.8em;
        font-size: 14px;
    }

    &._btn-lg {
        margin-top: 30px;
        padding: 0.8em 3em;
        font-size: 18px;
    }

    &._btn-xl {
        margin-top: 30px;
        padding: 0.9em 3.5em;
        font-size: 20px;
    }

    &._btn-block {
        display: block;
        width: 100%;
        text-align: center;
    }

    &._btn-no-margin {
        margin-top: 0;
    }

    &._btn-primary {
        border: 2px solid $flat-blue;
        color: $flat-blue;

        &:hover {
            border: 2px solid $flat-blue;
            background-color: $flat-blue;
            color: #fff;
        }
    }

    &._btn-success {
        border: 2px solid $flat-green;
        color: $flat-green;

        &:hover{
            border: 2px solid $flat-green;
            background-color: $flat-green;
            color: #fff;
        }
    }

     &._btn-danger {
        border: 2px solid $flat-red;
        color: $flat-red;

        &:hover {
            border: 2px solid $flat-red;
            background-color: $flat-red;
            color: #fff;
        }
    }

    &._btn-light-grey {
        border: 2px solid $flat-light-grey;
        color: $flat-light-grey;

        &:hover {
            border: 2px solid $flat-light-grey;
            background-color: $flat-light-grey;
            color: #fff;
        }
    }

    &._btn-disabled {
        border: 2px solid $flat-lighter-grey;
        color: $flat-lighter-grey;
        cursor: not-allowed;
        // pointer-events: none;

        &:hover {
            border: 2px solid $flat-lighter-grey;
            background-color: transparent;
            color: $flat-lighter-grey;
        }
    }

    ._btn-content-invisible {
        display: none;
        opacity: 0;
    }

    &._btn-processing {
        ._btn-content-visible {
            display: none;
            opacity: 0;
        }
        ._btn-content-invisible {
            display: inline;
            opacity: 1;
        }
    }

    .glyphicon {
        top: 2px;
        margin-right: 5px;
    }
}

// Panel
._panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 2px solid $flat-lighter-grey;

    ._panel-heading {
        padding: 10px 15px;
        letter-spacing: 1px;
        color: #fff;
        background-color: $flat-lighter-grey;

        ._panel-title {
            margin-top: 0;
            margin-bottom: 0;
            // font-size: 18px;
            color: inherit;

            small {
                color: #fff;
                vertical-align: 1px;
            }
        }
    }

    ._panel-body {
        padding: 15px;
        overflow: hidden;
        position: relative;

        &._panel-body-no-margin {
            margin-bottom: 0;
        }
    }

    &._panel-progress {
        ._panel-heading {
            padding: 0;

            ._progress {
                margin-bottom: 0;
            }
        }
    }

    &._panel-grey {
        border: 2px solid $flat-grey;

        & > ._panel-heading {
            background-color: $flat-grey;
        }
    }

    &._panel-royalblue {
        border: 2px solid $flat-royalblue;

        & > ._panel-heading {
            background-color: $flat-royalblue;
        }
    }

    &._panel-primary {
        border: 2px solid $flat-blue;

        & > ._panel-heading {
            background-color: $flat-blue;
        }
    }

    &._panel-danger {
        border: 2px solid $flat-red;

        & > ._panel-heading {
            background-color: $flat-red;
        }
    }

    &._panel-warning {
        border: 2px solid $flat-orange;

        & > ._panel-heading {
            background-color: $flat-orange;
        }
    }

    &._panel-success {
        border: 2px solid $flat-green;

        & > ._panel-heading {
            background-color: $flat-green;
        }
    }
}

// Square Panel
._square-panel {
    color: #fff;

    &._square-panel-primary {
        background-color: $flat-blue;
    }
}

// Progress Bar
._progress {
    overflow: hidden;
    height: 25px;
    margin-bottom: 20px;
    background-color: $flat-lighter-grey;

    ._progress-bar {
        float: left;
        width: 0%;
        height: 100%;
        font-size: 12px;
        line-height: 20px;
        color: #fff;
        text-align: center;
        background-color: $flat-grey;
        transition: width 0.6s ease;

        &._progress-bar-primary {
            background-color: $flat-blue;
        }

        &._progress-bar-success {
            background-color: $flat-green;
        }

        &._progress-bar-danger {
            background-color: $flat-red;
        }
    }
}

// Table
._table {
    position: relative;
    display: table;
    width: 100%;
    height: 100%;
}

._table-row {
    position: relative;
    display: table-row;
    height: 100%;
}

._table-cell {
    position: relative;
    display: table-cell;
    height: 100%;
}

.height-100 {
    height: 100%;
}

// Input file
._input-file-container {
    position: relative;

    ._input-file {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }

    ._input-file-trigger {
        // display: block;
        // padding: 14px 45px;
        // background: #39D2B4;
        // color: #fff;
        // font-size: 1em;
        // transition: all .4s;
        // cursor: pointer;
    }

    ._input-file:hover + ._input-file-trigger._btn-primary,
    ._input-file-trigger._btn-primary:hover {
        border: 2px solid $flat-blue;
        background-color: $flat-blue;
        color: #fff;
    }
}

._padding-radio-financement, .checkbox-inline {
    padding-left: 18px;
}


/*
|--------------------------------------------------------------------------
| Layout Principal
|--------------------------------------------------------------------------
|
*/

body {
    background: #fff;
    color: $flat-light-grey;
    font-family: 'Open Sans', Arial, sans-serif;
}

.main-header {
    display: block;
    width: 100%;
    padding: 20px;
    background-color: lighten(#1c1d22, 86%);

    ._title-contrat-numerique {
        text-align: center;
    }

    h1 {
        display: block;
        text-align: center;
        color: $flat-royalblue;
        font-family: Lato, sans-serif;
        font-weight: 400;
        line-height: 60px;
    }

    .header-infos {
        margin: 0;
        margin-top: 35px;
        // line-height: 55px;
    }
}

.main-container {
    padding: 0;
    margin-top: 50px;
    margin-bottom: 50px;

    .main-content {
        padding: 50px 0;
    }
}


/*
|--------------------------------------------------------------------------
| Espace Candidat
|--------------------------------------------------------------------------
|
*/

.espace-candidat {
    font-family: 'Open Sans', Arial, sans-serif;

    nav {
        position: fixed;
        padding: 0;
        // display: block;
        // width: 300px;
        height: 100%;
        color: #fff;
        background-color: #1c1d22;
        z-index: 2;

        .nav-title {
            width: 100%;
            height: 60px;
            padding-top: 20px;
            line-height: 20px;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 2px;
            // color: #3b3d4a;
        }

        ul {
            padding: 0;
            margin: 0;

            li {
                height: 50px;
                opacity: .5;
                transition-duration: .2s;

                &:hover {
                    opacity: 1;
                    background-color: lighten(#1c1d22, 10%);
                }
                &.active {
                    opacity: 1;
                    border-right: 5px solid $flat-blue;
                    background-color: lighten(#1c1d22, 10%)
                }

                a {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    line-height: 50px;
                    font-size: 18px;
                    letter-spacing: 1px;
                    text-decoration: none;
                    color: #fff;

                    i {
                        font-size: 20px;
                        margin-left: 20px;
                        vertical-align: -3px;
                    }

                    span {
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    .contrat-numerique-instructions {
        margin-bottom: 15px;

        .progress-item {
            line-height: 22px;

            .glyphicon {
                display: none;
            }

            &._success {
                .glyphicon {
                    display: inline;
                }
            }
        }
    }

    .contrat-numerique-actions {
        ._btn {
            margin-top: 0;
            // margin-bottom: 15px;

            .glyphicon {
                display: block;
                font-size: 25px;
                margin-bottom: 10px;
            }
        }
    }

    .contrat-numerique-conseiller {
        margin-bottom: 15px;
        font-size: 16px;

        & > .glyphicon {
            margin-right: 10px;
            font-size: 40px;
            vertical-align: -2px;
        }
        & > .conseiller-info {
            display: inline-block;
        }
    }

    .contrat-numerique-resume {
        .contrat-numerique-resume-title {
            margin-top: 10px;
            padding-bottom: 5px;
            border-bottom: 1px solid #ddd;

            &:first-child {
                margin-top: 0;
            }
        }
        .contrat-numerique-resume-price {
            display: block;
            font-size: 18px;
            font-weight: 600;
            text-align: right;
        }
        .contrat-numerique-resume-price-infos {
            color: $flat-light-grey;
            font-size: 12px;
            text-align: right;
            font-weight: normal;
        }
        .contrat-numerique-resume-price-formation {
            padding-bottom: 10px;
        }
        ul {
            list-style-type: none;
            padding: 0;

            & > li {
                color: $flat-light-grey;
            }
        }
    }

    .contrat-numerique-documents {
        table {
            th {
                padding-left: 15px;

                small {
                    font-weight: 400;
                }
            }
            thead {
                th:first-child {
                    width: 75%;
                }
            }
        }
    }

    .paiement-panel {
        table {
            margin-bottom: 0;

            tr:first-child > td {
                border-top: 0;
            }
        }
    }

}

.list-formations {
    .formation-total {
        margin: 0 0 10px 0;
    }
}


// END Custom Design 2016/01/29




.btn-main-blue {
    color: #fff;
    background: $blue_main;
    border: $blue_main;

    &:hover, &:focus {
        color: #fff;
        background: darken($blue_main, 10%);
    }

    &:active {
        background: darken($blue_main, 20%);
    }
}

.btn-main-green {
    color: #fff;
    background: $green_main;
    border: $green_main;

    &:hover, &:focus {
        color: #fff;
        background: darken($green_main, 10%);
    }

    &:active {
        background: darken($green_main, 20%);
    }
}

.panel-main-green {
    border-color: $panel-green-border;

    & > .panel-heading {
        background: $panel-green-heading-bg;
        color: $panel-text;
        border-color: $panel-green-border;

        + .panel-collapse .panel-body {
            border-top-color: $panel-green-border;
        }
    }

    & > .panel-footer {
        padding: 10px 15px;
        border-bottom: 1px solid transparent;
        border-top-radius: 4px;
    }
}

.panel-main-blue-light {
    border: 2px solid $blue-efc;
    border-radius: 0 !important;

    & > .panel-heading {
        background: $blue-efc;
        color: #fff;
        border-radius: 0 !important;

        + .panel-collapse .panel-body {
            border-top-color: #1E7DB6;
        }
    }

    & > .panel-footer {
        padding: 10px 15px;
        border-bottom: 1px solid transparent;
        border-top-radius: 4px;
    }

    /* .panel-footer {
    	border-top: 1px solid #1E7DB6;
    	background-color: #47A3DA;
    } */
}

// Session message
.session-message {
    position: fixed;
    display: block;
    width: 100%;
    margin-top: 0px;
    @include transition(all .25s ease);
    top: 0;
    right: 0;
    left: 0;
    z-index: 150;

    .alert {
        position: relative;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
}

//General
.lato {
    font-family: Lato, sans-serif;
}

.lato-light {
    font-weight: 300;
}

.h1-lg {
    font-size: 48px;
}

a {
    &:hover, &:focus {
        text-decoration: none;
    }
}

.expose {
    vertical-align: super;
    font-size: 66%;
}

.container {
    position: relative;
}

.grey {
    color: grey;
}

//Override panel
h1.panel-title {
    font-weight: 300;
    text-decoration: none;
    text-align: center;
    font-size: 2em;

    //font-family: Lato, sans-serif;
}

// Radio DCG
// Formation
.checkbox-ue-dcg {
    margin-bottom: 10px;
    // padding-left: 30px;
    position: relative;
    // max-height: 150px;
    overflow: hidden;

    // &:after {
    //     content: "";
    //     position: absolute;
    //     display: block;
    //     right: 0;
    //     left: 0;
    //     bottom: 0;
    //     height: 100%;

    //     /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&amp;0+0,1+100 */
    //     background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);

    //     /* FF3.6+ */
    //     background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1)));

    //     /* Chrome,Safari4+ */
    //     background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);

    //     /* Chrome10+,Safari5.1+ */
    //     background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);

    //     /* Opera 11.10+ */
    //     background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);

    //     /* IE10+ */
    //     background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);

    //     /* W3C */
    //     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );

    //     /* IE6-9 */
    // }

    &.dcg-selected {
        max-height: none;

        &:after {
            display: none;
        }
    }
}

// Formation / Financement Conseiller
// .formation-choosen {
//     .formation-choose {
//         font-size: 16px;
//         margin-bottom: 6px;
//     }
// }


// Questionnaire de positionnement
.questionnaire-positionnement {
    form {
        th {
            color: #959595;
        }
        .radio-block {
            display: block;
            position: relative;
            padding-left: 0px;
            margin-bottom: 0;
            font-weight: 400;
            vertical-align: middle;
            cursor: pointer;
        }
        table {
            .checkbox-inline, .radio-inline {
                text-align: center;
                display: block;
            }
            td {
                height: 51px;
                vertical-align: middle;
                & > .control-label {
                    padding-left: 12px;
                }
            }
            label {
                padding-top: 0 !important;
            }
        }

        // button[type='submit'] {
        //     display: block;
        //     margin: 0 auto;
        // }
    }
    .asterisk {
        font-size: 30px;
        line-height: 10px;
        vertical-align: -6px;
    }
}
.control-label {
    color: #959595;
}
.questionnaire-positionnement-legal {
    margin: 30px 0;
}



// -------------------------//
// Conseillor
// -------------------------//
.conseillor-authentification-data {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 0;
    border: 0;
    line-height: 34px;
}

// Form (infos) Animation on upadteing by Prospect
/* Minoru */
form.action-post-informations.animate-submit button[type="submit"] span.label-first {
    @include scale(0);

    @include opacity(0);
}

form.action-post-informations.animate-submit button[type="submit"] span.glyphicon-cog {
    @include opacity(1);

    @include scale(1);
}

form.action-post-informations.animate-submit button[type="submit"] span.glyphicon-ok {
    @include opacity(0);

    @include scale(0);
}

form.action-post-informations.animate-success button[type="submit"] span.label-first {
    @include opacity(0);

    @include scale(0);
}

form.action-post-informations.animate-success button[type="submit"] span.glyphicon-ok {
    @include scale(1);

    @include opacity(1);
}

// &.animate-submit┬á{
// 	button[type="submit"] {
// 		span.glyphicon-cog {
// 		}
// 		span.glyphicon-ok {
// 		}
// 	}
// }
form.action-post-informations {
    // .label-first {
    //     position: absolute;
    //     margin: 0 auto;
    //     display: block;
    //     width: 100%;
    //     top: 10px;

    //     @include scale(1);

    //     @include opacity(1);

    //     @include transition(all 0.2s ease-in);
    // }

    // .button-conainter {
    //     position: absolute;
    //     display: inline-block;
    //     width: 100%;
    //     top: 11px;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;

    //     .glyphicon {
    //         @include transition(all 0.2s ease-in);
    //     }
    // }

    // button[type="submit"] {
    //     width: 140px;
    //     height: 46px;
    //     padding: 0;
    //     position: relative;

    //     span.glyphicon-cog {
    //         display: inline-block;

    //         @include opacity(0);

    //         @include scale(0);
    //     }

    //     span.glyphicon-ok {
    //         display: inline-block;

    //         @include opacity(0);

    //         @include scale(0);
    //     }
    // }

    input {
        // &:focus {
        // 	box-shadow: 0px 0px 0px 2px #eca29b;
        // }

    }

    .input-field {
        position: relative;
        display: block;
    }

    .input-field:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        width: 100%;
        height: 34px;
        box-shadow: 0px 0px 0px 2px transparent;
        -webkit-transition: box-shadow 0.3s;
        transition: box-shadow 0.3s;
    }
}

// .formation-choosen {
//     position: relative;
//     &:after {
//         content: '';
//         position: absolute;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         box-shadow: 0px 0px 0px 2px transparent;
//         -webkit-transition: box-shadow 0.3s;
//         transition: box-shadow 0.3s;
//     }
// }

.input-field-focus {
    input, select, textarea {
        box-shadow: 0px 0px 0px 1px #66afe9;
    }
}

.input-field-focus:after {
    -webkit-animation: anim-shadow 0.3s forwards;
    animation: anim-shadow 0.3s forwards;
}

.repeat-spinner {
    animation: repeat $duration linear infinite;
}

@keyframes repeat {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes anim-shadow {
    to {
        box-shadow: 0px 0px 100px 50px darken(#66afe9, 20%);
        opacity: 0;
    }
}

@keyframes anim-shadow {
    to {
        box-shadow: 0px 0px 100px 50px darken(#66afe9, 20%);
        opacity: 0;
    }
}

.action-post-formations {
    .panel-body {
        position: relative;
        padding-top: 3px;
        padding-bottom: 3px;
    }
}

// Glyphicon Spinner
.glyphicon-spin {
    -webkit-animation: spin 2s linear infinite;

    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite;

    /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);

        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);

        /* IE 9 */
        transform: rotate(0deg);

        /* Firefox 16+, IE 10+, Opera */
    }

    100% {
        -webkit-transform: rotate(360deg);

        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);

        /* IE 9 */
        transform: rotate(360deg);

        /* Firefox 16+, IE 10+, Opera */
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);

        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);

        /* IE 9 */
        transform: rotate(0deg);

        /* Firefox 16+, IE 10+, Opera */
    }

    100% {
        -webkit-transform: rotate(360deg);

        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);

        /* IE 9 */
        transform: rotate(360deg);

        /* Firefox 16+, IE 10+, Opera */
    }
}

.paiement-cb-etat {
    .alert {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    table {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

// Override bootstraap
.alert {
    margin-top: 20px;
}

.label {
    display: inline-block;
}

.restricted {
    cursor:not-allowed;
}

.waiting-link {
    color: #E98F07;
}

._btn-waiting {
    border: 2px solid #E98F07;
    color: #E98F07;
    &:focus {
        color: #E98F07;
    }
    &:hover {
        border: 2px solid #E98F07;
        color: #ffffff;
        background: #E98F07;
    }
}

.resp-container-iframe {
    position: relative;
    overflow: hidden;
    min-height: 900px;
    max-width: 80%;
    margin: 0 auto;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}


/*========== Mobile First Method ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 340px) {
    .resp-container-iframe {
        min-height: 790px;
    }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
    .main-header{
        .container{
            align-items: center;
        }
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
    // Col-Table
    .row-table {
        display: table;
        table-layout: fixed;
        height: 100%;
        width: 100%;

        [class*="col-"] {
            float: none;
            display: table-cell;
            vertical-align: top;
            height: 100%;
        }
    }

    .inside-full-height {
        height: 100%;
        margin-top: 0;
        margin-bottom: 0;
    }

    .resp-container-iframe {
        min-height: 800px;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
    .main-header{
        .container{
            display:flex;
            align-items: center;
        }
    }
     .resp-container-iframe {
        max-width: 100%;
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {}

/*========== Non-Mobile First Method ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {}
